<template>
  <div
    :class="[
      isMobileScreen
        ? 'vd-padding-small vms-project-schedule--small'
        : 'vms-content__container vd-padding-medium',
    ]"
  >
    <default-loader v-if="isEventsListLoading"></default-loader>

    <div
      v-else-if="isProjectDraft"
      class="vms-project-schedule__draft vd-text-align-center"
    >
      <i class="vd-icon icon-calendar-days vd-grey"></i>
      <p>Activate Project to schedule</p>
    </div>

    <template v-else>
      <div class="vd-display-flex vd-align-center">
        <project-schedule-members-list
          class="vd-margin-right-auto"
        ></project-schedule-members-list>

        <project-schedule-settings></project-schedule-settings>
      </div>
      <div class="vms-project-schedule__progress">
        <ejs-progressbar
          v-if="canShowCalendarProgressBar"
          id="vms-scheduler-progress"
          ref="indeterminate"
          type="Linear"
          width="auto"
          height="2px"
          :is-indeterminate="true"
          progress-color="#00c5ff"
          :value="20"
        >
        </ejs-progressbar>
      </div>
      <project-schedule-scheduler></project-schedule-scheduler>

      <events-modal-controller v-if="showEventsModal"></events-modal-controller>
      <events-bulk-edit-modal
        v-if="showEventsBulkEditModal"
      ></events-bulk-edit-modal>
      <eod-notes-modal
        v-if="showEodModal"
        :project-id="eodNotesSelectedProject.id"
        :project-name="eodNotesSelectedProject.name"
      >
      </eod-notes-modal>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DefaultLoader from '@components/loaders/default-loader'
import EventsModalController from '@components/events/events-modal-controller'
import ProjectScheduleSettings from '@views/project/schedule/components/project-schedule-settings'
import ProjectScheduleMembersList from '@views/project/schedule/components/project-schedule-members-list'
import EventsBulkEditModal from '@components/modal/events/events-bulk-edit-modal'
import EodNotesModal from '@components/eod-notes/eod-notes-modal'
import ApiCustomIncludes from '@configs/api-custom-includes'
import momentTz from 'moment-timezone'
import VmsConstants from '@configs/vms-constants'

// This will lazy load scheduler to prevent it from delaying tab switching the first time schedule tab is loaded.
const ProjectScheduleScheduler = () =>
  import('@views/project/schedule/project-schedule-scheduler')

export default {
  components: {
    DefaultLoader,
    ProjectScheduleScheduler,
    EventsModalController,
    ProjectScheduleSettings,
    ProjectScheduleMembersList,
    EventsBulkEditModal,
    EodNotesModal,
  },

  provide: {
    eventsListIncludes: 'projectEventsList',
  },

  data() {
    return {
      showInitialLoader: true,
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
      canShowCalendarProgressBar: 'calendar/canShowCalendarProgressBar',
      isEventsListLoading: 'calendar/isEventsListLoading',
      showEventsModal: 'calendar/showEventsModal',
      showEventsBulkEditModal: 'calendar/showEventsBulkEditModal',
      showEodModal: 'project/showEodModal',
      eodNotesSelectedProject: 'project/eodNotesSelectedProject',
      productionTeam: 'project/productionTeam',
      isProjectDraft: 'project/isProjectDraft',
      projectEventsListPayload: 'project/schedule/projectEventsListPayload',
      projectDetails: 'project/projectDetails',
      selectedFilters: 'project/schedule/selectedFilters',
    }),
  },

  mounted() {
    if (!this.isProjectDraft) {
      this.setUsersList(this.productionTeam)
      this.getEventTypesFilterList()
    }

    // by default, set selected date to current date
    // to prevent other projects from using the selected date in another project.
    this.setSelectedDate(
      momentTz().startOf('day').format('YYYY-MM-DD HH:mm:ss')
    )
  },

  activated() {
    if (!this.isProjectDraft) {
      // Include on hold events by default
      this.setSelectedFilters({
        ...this.selectedFilters,
        event_status_ids: [
          VmsConstants.eventStatuses.EVENT_STATUS_ID_SCHEDULED,
          VmsConstants.eventStatuses.EVENT_STATUS_ID_UNSCHEDULED,
          VmsConstants.eventStatuses.EVENT_STATUS_ID_ON_HOLD,
        ],
      })

      this.getProjectEventsList({
        payload: this.projectEventsListPayload,
        showLoader: this.showInitialLoader,
      })
    }

    this.showInitialLoader = false
  },

  deactivated() {
    this.getProjectDetails({
      projectId: this.projectDetails?.id,
      customIncludes: ApiCustomIncludes.projectDetails,
    })
  },

  beforeDestroy() {
    this.setCustomEventsListPayload(null)
  },

  methods: {
    ...mapActions({
      setCustomEventsListPayload: 'calendar/setCustomEventsListPayload',
      getEventTypesFilterList: 'calendar/getEventTypesFilterList',
      getProjectEventsList: 'project/schedule/getProjectEventsList',
      setUsersList: 'project/schedule/setUsersList',
      getProjectDetails: 'project/getProjectDetails',
      setSelectedDate: 'project/schedule/setSelectedDate',
      setSelectedFilters: 'project/schedule/setSelectedFilters',
    }),
  },
}
</script>

<style lang="scss">
@import '@styles/v2/views/calendar/calendar.scss';
@import '@styles/v2/plugins/syncfusion.scss';
@import '@styles/v2/views/project/schedule/schedule.scss';
</style>
