<template>
  <default-modal
    :class="[
      'show vd-text-align-left',
      isMobileScreen ? 'vms-modal--fullscreen' : '',
    ]"
    modal-dialog-class="modal-xl"
    content-class="vd-background-white"
  >
    <div slot="modal-header" class="modal-header">
      <div class="vd-h4 vd-black">Deleted events</div>
      <span
        v-if="!isDeletedEventsLoading"
        id="closeEventsOnHoldModal"
        class="vms-modal__header--close close vd-black"
        @click="handleCancel"
      >
        <span id="closeEventsOnHoldModal">x</span>
      </span>
    </div>

    <div slot="modal-body" class="modal-body">
      <div class="row no-gutters">
        <div class="col-5">
          <search-component
            v-if="!isDeletedEventsLoading"
            class="vd-full-width"
            :search-text="searchKeyword"
            @input:search="searchKeyword = $event"
            @enter:search="handleSearchDeletedEvents"
          ></search-component>
        </div>

        <div class="col-7 vd-margin-top-small vd-margin-bottom-small">
          <pagination-component
            v-show="canShowPagination"
            :total-rows="deletedEvents.meta.total"
            :per-page="deletedEvents.meta.per_page"
            :last-page="deletedEvents.meta.last_page"
            :show-page-in-route="false"
            :page="page"
            :can-update-pagination-limit="false"
            @update:page="currentPageUpdate"
          ></pagination-component>
        </div>
      </div>

      <div>
        <default-loader v-if="isDeletedEventsLoading"></default-loader>

        <div
          v-else
          :class="[
            'vd-margin-top-medium vd-padding-top-small',
            canShowBorder
              ? 'vd-border-light-grey vd-border-bottom-width-0'
              : '',
          ]"
        >
          <template v-if="hasDeletedEvents">
            <events-card-content
              v-if="isMobileScreen"
              :calendar-events="deletedEvents.data"
            >
              <template v-slot:action="{ event }">
                <button
                  class="vd-btn-blue vd-btn-small"
                  @click="handleRestoreDeletedEvent(event)"
                >
                  <span class="vd-display-flex">
                    <i
                      class="vd-icon icon-play-rotate-right vd-margin-right-extra-small"
                    ></i>
                    Restore
                  </span>
                </button>
              </template>
            </events-card-content>
            <events-table-content
              v-else
              :calendar-events="deletedEvents.data"
              :action="action"
            >
              <template v-slot:action="{ event }">
                <i
                  class="vd-icon icon-play-rotate-right vd-hover-black vd-cursor-pointer"
                  @click="handleRestoreDeletedEvent(event)"
                ></i>
              </template>
            </events-table-content>
          </template>
          <div v-else class="vd-margin-bottom-medium vd-text-align-center">
            <span>No hold events found.</span>
          </div>
        </div>
      </div>

      <pagination-component
        v-show="canShowPagination"
        :total-rows="deletedEvents.meta.total"
        :per-page="deletedEvents.meta.per_page"
        :last-page="deletedEvents.meta.last_page"
        :show-page-in-route="false"
        :page="page"
        :can-update-pagination-limit="false"
        @update:page="currentPageUpdate"
      ></pagination-component>
    </div>
  </default-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DefaultModal from '@components/modal/default-modal'
import SearchComponent from '@components/search/search'
import PaginationComponent from '@components/pagination/pagination'
import EventsTableContent from '@components/modal/events/components/events-table-content/events-table-content'
import EventsCardContent from '@components/modal/events/components/events-card-content'
import ApiCustomIncludes from '@configs/api-custom-includes'
import DefaultLoader from '@components/loaders/default-loader'
import VmsConstants from '@configs/vms-constants'
import momentTz from 'moment-timezone'
import { size } from 'lodash'

export default {
  components: {
    DefaultModal,
    SearchComponent,
    PaginationComponent,
    EventsTableContent,
    DefaultLoader,
    EventsCardContent,
  },

  props: {
    projectId: {
      type: Number,
      default: null,
      required: false,
    },
  },

  data() {
    return {
      perPage: 5,
      searchKeyword: '',
      page: 1,

      action: {
        icon: 'icon-plus',
        handler: this.handleRestoreDeletedEvent,
      },
    }
  },

  computed: {
    ...mapGetters({
      isMobileScreen: 'common/isMobileScreen',
      deletedEvents: 'calendar/deletedEvents',
      isDeletedEventsLoading: 'calendar/isDeletedEventsLoading',
      eventsListPayload: 'calendar/eventsListPayload',
      isUnHoldEventLoading: 'calendar/isUnHoldEventLoading',
    }),

    hasDeletedEvents() {
      return size(this.deletedEvents.data) > 0
    },

    canShowPagination() {
      return !this.isDeletedEventsLoading && this.hasDeletedEvents
    },

    canShowBorder() {
      return this.hasDeletedEvents && !this.isMobileScreen
    },

    projectIds() {
      return this.projectId ? [this.projectId] : undefined
    },

    payload() {
      return {
        all: 0,
        page: this.page,
        per_page: this.perPage,
        project_ids: this.projectIds,
        filters: {
          event_status_ids: [
            VmsConstants.eventStatuses.EVENT_STATUS_ID_SCHEDULED,
            VmsConstants.eventStatuses.EVENT_STATUS_ID_UNSCHEDULED,
            VmsConstants.eventStatuses.EVENT_STATUS_ID_ON_HOLD,
          ],
          search_term: this.searchKeyword,
          unassigned: 1,
          deleted_only: 1,
        },
        include: ApiCustomIncludes.deletedEvents,
      }
    },
  },

  mounted() {
    this.getDeletedEvents({
      payload: this.payload,
    })
  },

  methods: {
    ...mapActions({
      setShowDeletedEventsModal: 'calendar/setShowDeletedEventsModal',
      getDeletedEvents: 'calendar/getDeletedEvents',
      bulkRestoreDeletedEvent: 'calendar/bulkRestoreDeletedEvent',
      getEventsList: 'calendar/getEventsList',
    }),

    handleCancel() {
      this.setShowDeletedEventsModal(false)

      this.getEventsList({
        payload: this.eventsListPayload,
        showLoader: true,
      })
    },

    handleSearchDeletedEvents(searchKeyword) {
      this.searchKeyword = searchKeyword
      this.page = 1
      this.getDeletedEvents({
        payload: this.payload,
      })
    },

    currentPageUpdate(page) {
      // check if the selected page is not more than the meta last page
      if (page <= this.deletedEvents.meta.last_page) {
        this.page = page
      } else {
        this.page = this.deletedEvents.meta.last_page
      }

      this.getDeletedEvents({
        payload: this.payload,
      })
    },

    handleRestoreDeletedEvent(event) {
      this.bulkRestoreDeletedEvent({
        eventStartDate: momentTz.unix(event.from).format('D MMMM YYYY'),
        payload: {
          event_ids: [event.id],
        },
        getDeletedEventsPayload: this.payload,
      })
    },
  },
}
</script>
