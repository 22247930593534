<template>
  <div v-click-outside="closeSettings" class="vd-position-relative">
    <div
      class="vd-padding-block-extra-small vd-padding-inline-small vd-border-radius-4 vd-border-width-1 vd-background-white vd-border-grey vd-display-flex vd-cursor-pointer vd-hover-background-lighter-grey"
      @click="toggleSettingsDropdown"
    >
      <div class="vd-margin-right-small">Settings</div>
      <i
        class="vd-icon icon-chevron-down vd-hover-darker-grey vd-text-small vd-align-middle"
      ></i>
    </div>

    <div
      v-show="showSettings"
      class="vms-dropdown vms-project-schedule__dropdown vms-project-schedule__dropdown--right"
    >
      <div class="vd-box-shadow vd-background-white vd-border-radius-6">
        <div class="vd-border-grey vd-border-width-0 vd-border-bottom-width-1">
          <div
            class="vms-dropdown__list vd-black vd-padding-small"
            @click="handleOpenOnHoldEvents"
          >
            Open on hold events
          </div>

          <div
            class="vms-dropdown__list vd-black vd-padding-small"
            @click="handleViewDeletedEvents"
          >
            View deleted events
          </div>
        </div>

        <div
          class="vms-dropdown__list vd-black vd-padding-small vd-display-flex"
        >
          <input
            id="showGoogleBambooEventsCheckbox"
            v-model="showGoogleBambooEvents"
            type="checkbox"
            class="vd-margin-right-small"
            @change="handleShowGoogleBambooEvents"
          />

          <label
            for="showGoogleBambooEventsCheckbox"
            class="vd-margin-0 vd-cursor-pointer"
          >
            <span class="vd-margin-right-small">Show Google/Bamboo events</span>
          </label>
        </div>

        <div
          class="vms-dropdown__list vd-black vd-padding-small vd-display-flex"
        >
          <input
            id="showOnHoldEvents"
            v-model="showOnHoldEvents"
            type="checkbox"
            class="vd-margin-right-small"
            @change="handleShowOnHoldEventsChange"
          />

          <label for="showOnHoldEvents" class="vd-margin-0 vd-cursor-pointer">
            <span class="vd-margin-right-small">Show on hold events</span>
          </label>
        </div>

        <div
          class="vms-dropdown__list vd-black vd-padding-small vd-display-flex"
        >
          <input
            id="showOtherEventsCheckbox"
            v-model="showOtherEvents"
            type="checkbox"
            class="vd-margin-right-small"
            @change="handleShowOtherEventsChange"
          />

          <label
            for="showOtherEventsCheckbox"
            class="vd-margin-0 vd-cursor-pointer"
          >
            <span class="vd-margin-right-small">Show all other events</span>
          </label>
        </div>
      </div>
    </div>

    <on-hold-events-modal
      v-if="canShowOnHoldEventsModal"
      :project-id="projectDetails.id"
    ></on-hold-events-modal>

    <deleted-events-modal
      v-if="canShowDeletedEventsModal"
      :project-id="projectDetails.id"
    ></deleted-events-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import OnHoldEventsModal from '@components/modal/events/events-on-hold-modal'
import DeletedEventsModal from '@components/modal/events/events-deleted-modal'
import ClickOutside from 'vue-click-outside'
import VmsConstants from '@configs/vms-constants'

export default {
  components: {
    OnHoldEventsModal,
    DeletedEventsModal,
  },

  directives: {
    ClickOutside,
  },

  data() {
    return {
      showSettings: false,
      showOtherEvents: false,
      showGoogleBambooEvents: false,
      showOnHoldEvents: true,
    }
  },

  computed: {
    ...mapGetters({
      projectDetails: 'project/projectDetails',
      canShowOnHoldEventsModal: 'calendar/canShowOnHoldEventsModal',
      canShowDeletedEventsModal: 'calendar/canShowDeletedEventsModal',
      selectedFilters: 'project/schedule/selectedFilters',
      projectEventsListPayload: 'project/schedule/projectEventsListPayload',
    }),
  },

  methods: {
    ...mapActions({
      setShowOtherEvents: 'project/schedule/setShowOtherEvents',
      setSelectedFilters: 'project/schedule/setSelectedFilters',
      getProjectEventsList: 'project/schedule/getProjectEventsList',
      setShowOnHoldEventsModal: 'calendar/setShowOnHoldEventsModal',
      setShowDeletedEventsModal: 'calendar/setShowDeletedEventsModal',
    }),

    toggleSettingsDropdown() {
      this.showSettings = !this.showSettings
    },

    closeSettings() {
      this.showSettings = false
    },

    handleShowOtherEventsChange() {
      this.setShowOtherEvents(this.showOtherEvents)
      this.getProjectEventsList({
        payload: this.projectEventsListPayload,
        showLoader: false,
      })

      this.closeSettings()
    },

    handleShowGoogleBambooEvents() {
      const showGoogleBambooEvents = this.showGoogleBambooEvents ? 1 : 0

      this.setSelectedFilters({
        ...this.selectedFilters,
        include_google_events: showGoogleBambooEvents,
        include_bamboo_events: showGoogleBambooEvents,
      })

      this.getProjectEventsList({
        payload: this.projectEventsListPayload,
        showLoader: false,
      })

      this.closeSettings()
    },

    handleShowOnHoldEventsChange() {
      let eventStatusIds = [
        VmsConstants.eventStatuses.EVENT_STATUS_ID_SCHEDULED,
        VmsConstants.eventStatuses.EVENT_STATUS_ID_UNSCHEDULED,
      ]

      if (this.showOnHoldEvents) {
        eventStatusIds = [
          VmsConstants.eventStatuses.EVENT_STATUS_ID_SCHEDULED,
          VmsConstants.eventStatuses.EVENT_STATUS_ID_UNSCHEDULED,
          VmsConstants.eventStatuses.EVENT_STATUS_ID_ON_HOLD,
        ]
      }

      this.setSelectedFilters({
        ...this.selectedFilters,
        event_status_ids: eventStatusIds,
      })

      this.getProjectEventsList({
        payload: this.projectEventsListPayload,
        showLoader: false,
      })

      this.closeSettings()
    },

    handleOpenOnHoldEvents() {
      this.setShowOnHoldEventsModal(true)
      this.closeSettings()
    },

    handleViewDeletedEvents() {
      this.setShowDeletedEventsModal(true)
      this.closeSettings()
    },
  },
}
</script>
