<template>
  <div class="vd-display-flex items-center">
    <span class="vd-dark-grey vd-margin-right-small">Add team member</span>
    <div v-click-outside="closeUsersList" class="vd-position-relative">
      <button class="vd-text-small" @click="toggleUsersList">
        <i
          class="vd-icon icon-plus vd-icon-circle vd-background-light-blue vd-white"
        ></i>
      </button>

      <div
        v-show="showUsersList"
        class="vms-dropdown vms-project-schedule__dropdown vd-box-shadow vd-background-white vd-border-radius-6 vd-overflow-hidden"
      >
        <search-component
          class="vd-full-width vd-padding-small"
          :search-text="searchKeyword"
          @input:search="searchKeyword = $event"
        ></search-component>
        <div
          v-if="isInternalUsersLoading"
          class="vd-padding-medium vd-padding-top-0 vd-light-blue"
        >
          Loading...
        </div>
        <div v-else class="vms-project-schedule__dropdown-list">
          <div
            v-for="(user, index) in filteredUsers"
            :key="index"
            class="vd-display-flex vd-align-items-center vd-cursor-pointer vd-hover-background-lightest-grey vd-padding-small"
            @click="handleSelectUser(user)"
          >
            <user-profile
              :user="user"
              :custom-avatar-font-size="35"
              class="vd-flex-shrink-0"
            ></user-profile>
            <span class="vd-margin-left-small"> {{ user.full_name }} </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ApiCustomIncludes from '@configs/api-custom-includes'
import SearchComponent from '@components/search/search'
import UserProfile from '@components/user-profile/user-profile'
import { filter, includes, find, isEmpty } from 'lodash'
import ClickOutside from 'vue-click-outside'

export default {
  directives: {
    ClickOutside,
  },
  components: {
    SearchComponent,
    UserProfile,
  },

  data() {
    return {
      showUsersList: false,
      searchKeyword: '',
    }
  },

  computed: {
    ...mapGetters({
      internalUsersList: 'user/internalUsersList',
      isInternalUsersLoading: 'user/isInternalUsersLoading',
      usersList: 'project/schedule/usersList',
      projectEventsListPayload: 'project/schedule/projectEventsListPayload',
    }),

    filteredUsers() {
      return filter(this.internalUsersList, (user) => {
        return (
          includes(
            String(user.full_name).toLocaleLowerCase(),
            this.searchKeyword.toLocaleLowerCase()
          ) &&
          !find(this.usersList, { id: user.id }) &&
          user.status !== 'Inactive'
        )
      })
    },
  },

  mounted() {
    if (isEmpty(this.internalUsersList)) {
      this.getInternalUsers({
        includes: ApiCustomIncludes.eventsUser,
      })
    }
  },

  methods: {
    ...mapActions({
      getInternalUsers: 'user/getInternalUsers',
      setUsersList: 'project/schedule/setUsersList',
      getProjectEventsList: 'project/schedule/getProjectEventsList',
    }),

    closeUsersList() {
      this.showUsersList = false
      this.searchKeyword = ''
    },

    toggleUsersList() {
      this.showUsersList = !this.showUsersList
    },

    handleSelectUser(user) {
      this.setUsersList([...this.usersList, user])

      this.getProjectEventsList({
        payload: this.projectEventsListPayload,
        showLoader: false,
      })

      this.closeUsersList()
    },
  },
}
</script>
