var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-modal',{class:[
    'show vd-text-align-left',
    _vm.isMobileScreen ? 'vms-modal--fullscreen' : '' ],attrs:{"modal-dialog-class":"modal-xl","content-class":"vd-background-white"}},[_c('div',{staticClass:"modal-header",attrs:{"slot":"modal-header"},slot:"modal-header"},[_c('div',{staticClass:"vd-h4 vd-black"},[_vm._v("Deleted events")]),(!_vm.isDeletedEventsLoading)?_c('span',{staticClass:"vms-modal__header--close close vd-black",attrs:{"id":"closeEventsOnHoldModal"},on:{"click":_vm.handleCancel}},[_c('span',{attrs:{"id":"closeEventsOnHoldModal"}},[_vm._v("x")])]):_vm._e()]),_c('div',{staticClass:"modal-body",attrs:{"slot":"modal-body"},slot:"modal-body"},[_c('div',{staticClass:"row no-gutters"},[_c('div',{staticClass:"col-5"},[(!_vm.isDeletedEventsLoading)?_c('search-component',{staticClass:"vd-full-width",attrs:{"search-text":_vm.searchKeyword},on:{"input:search":function($event){_vm.searchKeyword = $event},"enter:search":_vm.handleSearchDeletedEvents}}):_vm._e()],1),_c('div',{staticClass:"col-7 vd-margin-top-small vd-margin-bottom-small"},[_c('pagination-component',{directives:[{name:"show",rawName:"v-show",value:(_vm.canShowPagination),expression:"canShowPagination"}],attrs:{"total-rows":_vm.deletedEvents.meta.total,"per-page":_vm.deletedEvents.meta.per_page,"last-page":_vm.deletedEvents.meta.last_page,"show-page-in-route":false,"page":_vm.page,"can-update-pagination-limit":false},on:{"update:page":_vm.currentPageUpdate}})],1)]),_c('div',[(_vm.isDeletedEventsLoading)?_c('default-loader'):_c('div',{class:[
          'vd-margin-top-medium vd-padding-top-small',
          _vm.canShowBorder
            ? 'vd-border-light-grey vd-border-bottom-width-0'
            : '' ]},[(_vm.hasDeletedEvents)?[(_vm.isMobileScreen)?_c('events-card-content',{attrs:{"calendar-events":_vm.deletedEvents.data},scopedSlots:_vm._u([{key:"action",fn:function(ref){
        var event = ref.event;
return [_c('button',{staticClass:"vd-btn-blue vd-btn-small",on:{"click":function($event){return _vm.handleRestoreDeletedEvent(event)}}},[_c('span',{staticClass:"vd-display-flex"},[_c('i',{staticClass:"vd-icon icon-play-rotate-right vd-margin-right-extra-small"}),_vm._v(" Restore ")])])]}}],null,false,2178663501)}):_c('events-table-content',{attrs:{"calendar-events":_vm.deletedEvents.data,"action":_vm.action},scopedSlots:_vm._u([{key:"action",fn:function(ref){
        var event = ref.event;
return [_c('i',{staticClass:"vd-icon icon-play-rotate-right vd-hover-black vd-cursor-pointer",on:{"click":function($event){return _vm.handleRestoreDeletedEvent(event)}}})]}}],null,false,1913513180)})]:_c('div',{staticClass:"vd-margin-bottom-medium vd-text-align-center"},[_c('span',[_vm._v("No hold events found.")])])],2)],1),_c('pagination-component',{directives:[{name:"show",rawName:"v-show",value:(_vm.canShowPagination),expression:"canShowPagination"}],attrs:{"total-rows":_vm.deletedEvents.meta.total,"per-page":_vm.deletedEvents.meta.per_page,"last-page":_vm.deletedEvents.meta.last_page,"show-page-in-route":false,"page":_vm.page,"can-update-pagination-limit":false},on:{"update:page":_vm.currentPageUpdate}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }